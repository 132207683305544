import * as _ from 'lodash'
import { getTranslationByPlugin, isWixEmployeeEmail } from '../../utils/utils'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
} from '../../constants/roles'
import translations from '../../utils/translations'
import { ComponentConnection, ComponentRefAndConfig, Plugin } from '../../constants/api-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import RemoteApi from '../../panels/commons/remote-api'
import { EVENTS } from '../../constants/bi'
import { getPrimaryConnection, getValidCollectionId } from './utils'
import { undoable, withBi } from './decorators'
import { generateRuntimeCoreApi } from './api-generator'
import LayoutApi from './layout-panel/api'
import StyleApi from './style-panel/api'
import AddFormApi from './add-form/api'
import CollectionsApi from './collections/api'
import SettingsApi, { SETTINGS_API_NAME } from './settings-panel/api'
import FirstTimeApi from './first-time-panel/api'
import ManagePanelsApi from './manage-panels/api'
import FieldsApi from './fields/api'
import PremiumApi from './premium/api'
import StepsApi from './steps/api'
import AppState from './app-state/app-state'
import {
  FieldNameType,
  FieldPreset,
  FormsFieldPreset,
  MissingField,
  RegistrationFieldPreset,
} from '../../constants/field-types'
import {
  ALL_FIELDS_DATA,
  allowCollectionSync,
  FieldProperties,
} from './preset/fields/field-types-data'
import { FormPlugin } from '../../constants/plugins'
import { CRM_TAGS, CRM_TYPES } from '../../constants/crm-types-tags'
import Experiments from '@wix/wix-experiments'
import PluginsApi from './plugins/api'
import {
  convertPluginsToFormsPlugins,
  getActivePlugin,
  getPlugins,
} from './plugins/utils'
import { REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES } from './plugins/registration-form'
import { PluginV2 } from '../../types/domain-types'
import { NOTIFICATION_EVENTS } from '../constans/EVENTS'
import { PanelName } from './manage-panels/consts/panel-names'
import { TABS } from '../../panels/form-settings-panel/constants'
import { COMPONENT_TYPES } from '../../constants/component-types'
import { FedopsLogger } from '@wix/fedops-logger'
import { OLD_CONTROLLER_TYPE } from './manifests/app-manifest'
import { EditorType, Origin } from '@wix/platform-editor-sdk'
import ContactSyncApi from './contact-sync/api'

export default class CoreApi {
  private experiments: Experiments
  private ravenInstance: RavenInstance
  public remoteApi: RemoteApi
  private biLogger: BILogger
  private editorType: EditorType
  private fedopsLogger: FedopsLogger
  private siteId: string

  public layout: LayoutApi
  public style: StyleApi
  public addForm: AddFormApi
  public collectionsApi: CollectionsApi
  public settings: Partial<SettingsApi>
  public firstTimePanel: FirstTimeApi
  public managePanels: ManagePanelsApi
  public fields: FieldsApi
  public contactSync: ContactSyncApi
  public premium: PremiumApi
  public appState: AppState
  public plugins: PluginsApi
  public steps: StepsApi

  constructor(
    private boundEditorSDK,
    private editorSDK,
    {
      apis: { collectionsApi, remoteApi },
      experiments,
      ravenInstance,
      biLogger,
      origin,
      appDefinitionId,
      fedopsLogger,
      siteId,
    }: {
      apis: { collectionsApi: CollectionsApi; remoteApi: RemoteApi }
      experiments: Experiments
      ravenInstance: RavenInstance
      biLogger: BILogger
      origin: Origin
      appDefinitionId: string
      fedopsLogger: FedopsLogger
      siteId: string
    }
  ) {
    this.fedopsLogger = fedopsLogger
    const helpers = { experiments, biLogger, ravenInstance, fedopsLogger }
    this.collectionsApi = new CollectionsApi(
      boundEditorSDK,
      collectionsApi,
      appDefinitionId,
      helpers
    )
    this.remoteApi = remoteApi
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.biLogger = biLogger
    this.editorType = _.get(origin, 'type')
    this.siteId = siteId

    this.layout = new LayoutApi(boundEditorSDK, this, helpers)
    this.style = new StyleApi(boundEditorSDK, this, helpers)
    this.addForm = new AddFormApi(boundEditorSDK, this, helpers)
    this[SETTINGS_API_NAME] = new SettingsApi(boundEditorSDK, editorSDK, this, remoteApi, helpers)
    this.managePanels = new ManagePanelsApi(boundEditorSDK, editorSDK, this, helpers)
    this.fields = new FieldsApi(boundEditorSDK, this, remoteApi, helpers)
    this.contactSync = new ContactSyncApi(boundEditorSDK, this, remoteApi, helpers)
    this.premium = new PremiumApi(boundEditorSDK, this, remoteApi, helpers)
    this.appState = new AppState(boundEditorSDK, this, remoteApi, helpers)
    this.firstTimePanel = new FirstTimeApi(boundEditorSDK)
    this.plugins = new PluginsApi(this)
    this.steps = new StepsApi(boundEditorSDK, this, helpers)
  }

  public async fetchAppConfig({ formComponentRef }: { formComponentRef: ComponentRef }) {
    const appConfig: AppConfig = await this.formsExtendApi({
      formComponentRef,
      api: 'appConfig',
      payload: { formComponentRef },
    })

    return _.merge({}, appConfig)
  }

  public async formsExtendApi({
    formComponentRef,
    api,
    payload = null,
  }: {
    formComponentRef: ComponentRef
    api: string
    payload?: any
  }) {
    const fallback = await this.plugins.callDefaultApi(api, payload)

    try {
      const componentConnection = await this.getComponentConnection(formComponentRef)
      const plugins = getPlugins(componentConnection)
      const pluginsApi = this.plugins.withPlugins(plugins)

      if (pluginsApi.supportApi(api)) {
        const res = await pluginsApi.callApi(api, payload)

        if (_.isPlainObject(fallback)) {
          return _.merge({}, fallback, res)
        }

        return res
      }

      return _.merge({}, fallback)
    } catch (err) {
      return _.merge({}, fallback)
    }
  }

  public getExperiments() {
    return Promise.resolve(this.experiments.all())
  }

  public interactionStarted(interactionName) {
    this.fedopsLogger.interactionStarted(interactionName)
  }

  public interactionEnded(interactionName) {
    this.fedopsLogger.interactionEnded(interactionName)
  }

  public isResponsive() {
    return this.editorType === 'RESPONSIVE'
  }

  public isADI() {
    return this.editorType === 'ADI'
  }

  public isClassicEditor() {
    return this.editorType === 'CLASSIC'
  }

  public originEditorType() {
    return this.editorType
  }

  public setEditorType(editorType) {
    this.editorType = editorType
  }

  public setBiLogger(biLogger) {
    this.biLogger = biLogger
  }

  public log(payload = {}) {
    this.biLogger.log(payload)
  }

  public async connect(
    { connectionConfig, role },
    controllerRef: ComponentRef,
    connectToRef: ComponentRef
  ) {
    await this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary: true,
    })
    return { role, connectionConfig, connectToRef, controllerRef }
  }

  public async addComponentAndConnect(
    { data, connectionConfig, role },
    controllerRef: ComponentRef,
    containerRef: ComponentRef
  ) {
    const connectToRef = await this.boundEditorSDK.components.add({
      componentDefinition: data,
      pageRef: containerRef,
    })

    return this.connect({ connectionConfig, role }, controllerRef, connectToRef)
  }

  public async getFormId(componentRef) {
    const formCompRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    return formCompRef.id
  }

  public async filterAppManifestV2(manifest) {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()

    const controllersData = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.controllers.getData({ controllerRef })
      )
    )

    const componentsType = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.components.getType({ componentRef: controllerRef })
      )
    )

    const hasOldControllerType = _.some(controllersData, v => v.type === OLD_CONTROLLER_TYPE)
    const hasNoneDuplicableComponentType = _.some(
      componentsType,
      v => v !== 'platform.components.AppWidget'
    )

    const filteredControllersManifest = _.chain(manifest.controllersStageData)
      .mapValues((controllerTypeManifest, controllerType) => {
        if (controllerType === OLD_CONTROLLER_TYPE && !hasOldControllerType) {
          return null
        }

        return _.chain(controllerTypeManifest)
          .mapValues((controllerStateManifest, controllerState) => {
            if (controllerState === 'default' || hasNoneDuplicableComponentType) {
              return controllerStateManifest
            }

            return _.startsWith(controllerState, 'duplicatable') ? controllerStateManifest : null
          })
          .pickBy(_.identity)
          .value()
      })
      .pickBy(_.identity)
      .value()

    return {
      controllersStageData: { ...filteredControllersManifest },
    }
  }

  public async filterAppManifest(manifest) {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()

    // TODO: Make captureBreadcrumb general to all editorSDK calls
    this.ravenInstance.captureBreadcrumb({
      category: 'app-manifest',
      message: 'controllers.listAllControllers',
      data: controllers,
    })

    const controllersData = await Promise.all(
      _.map(controllers, ({ controllerRef }) =>
        this.boundEditorSDK.controllers.getData({ controllerRef })
      )
    )

    this.ravenInstance.captureBreadcrumb({
      category: 'app-manifest',
      message: 'controllers.getData',
      data: controllersData,
    })

    const controllerTypes = _.map(controllersData, controllerData => controllerData.type)
    const filteredControllersData = _.pick(manifest.controllersStageData, controllerTypes)

    return {
      controllersStageData: { ...filteredControllersData },
    }
  }

  public async findConnectedComponent(
    controllerRef: ComponentRef,
    childRole
  ): Promise<ComponentRef> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const obj = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.getComponentConnection(child)
        return role === childRole ? child : null
      })
    )
    return <ComponentRef | null>_.find(obj, x => !!x)
  }

  public async findChildComponentsByRole(
    ancestorComponentRef: ComponentRef,
    roleToFilter: string | string[]
  ): Promise<ComponentRef[]> {
    const rolesToFilter = _.castArray(roleToFilter)
    const { controllerRef } = await this.getComponentConnection(ancestorComponentRef)
    const filteredByRoleComponents = await this.findConnectedComponentsByRole(
      controllerRef,
      rolesToFilter
    )
    const childrenWithRole = await Promise.all(
      filteredByRoleComponents.map(async comp => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef: comp })
        return _.find(ancestors, ancestor => _.isEqual(ancestor, ancestorComponentRef)) && comp
      })
    )
    return childrenWithRole.filter(child => child)
  }

  public async findConnectedComponentsByFieldType(
    controllerRef: ComponentRef,
    componentFieldType: FieldPreset
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const obj = await Promise.all(
      childComps.map(async child => {
        const { config } = await this.getComponentConnection(child)
        const fieldType = _.get(config, 'fieldType')
        return fieldType === componentFieldType ? child : null
      })
    )

    return <ComponentRef[]>_.filter(obj, x => !!x)
  }

  public async findConnectedComponentsByRole(
    controllerRef: ComponentRef,
    roleToFilter: string | string[]
  ): Promise<ComponentRef[]> {
    const rolesToFilter = _.castArray(roleToFilter)
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    return this._filterComponentsByRole(childComps, rolesToFilter)
  }

  public async findConnectedComponentsByPlugin(
    controllerRef: ComponentRef,
    plugin: FormPlugin
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    return this._filterComponentsByRole(childComps, ROLE_FORM, {
      config: { plugins: [{ id: plugin }] },
    })
  }

  private async _filterComponentsByRole(
    components: ComponentRef[],
    roleToFilter: string | string[],
    dataToFilter = {}
  ) {
    const rolesToFilter = _.castArray(roleToFilter)
    const childrenData = await this.boundEditorSDK.components.get({
      componentRefs: components,
      properties: ['connections'],
    })

    return _.chain(childrenData)
      .filter(child =>
        _.find(
          child.connections,
          connection =>
            connection.isPrimary &&
            _.includes(rolesToFilter, connection.role) &&
            _.isMatch(connection, dataToFilter)
        )
      )
      .map(compData => compData.componentRef)
      .value()
  }

  public async findComponentByRole(componentRef: ComponentRef, childRole) {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    return this.findConnectedComponent(controllerRef, childRole)
  }

  public async getFormContainerOfAppWidget(controllerRef: ComponentRef) {
    return (await this.boundEditorSDK.components.getChildren({ componentRef: controllerRef }))[0]
  }

  public generateRuntimeApi() {
    return generateRuntimeCoreApi(
      this,
      {
        layout: this.layout,
        style: this.style,
        settings: this.settings,
        firstTimePanel: this.firstTimePanel,
        addForm: this.addForm,
        managePanels: this.managePanels,
        fields: this.fields,
        contactSync: this.contactSync,
        premium: this.premium,
        appState: this.appState,
        steps: this.steps,
      },
      this.ravenInstance
    )
  }

  public async getComponentConnection(componentRef: ComponentRef): Promise<ComponentConnection> {
    const connections = await this.boundEditorSDK.controllers.listConnections({
      componentRef,
    })

    return getPrimaryConnection(connections)
  }

  public async getPlugins(componentRef) {
    const componentConnection = await this.getComponentConnection(componentRef)
    return convertPluginsToFormsPlugins(getPlugins(componentConnection))
  }

  public async setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true
  ) {
    const { controllerRef, role, config } = await this.getComponentConnection(connectToRef)
    const mergedConfig = (deepMerge ? _.merge : _.assign)({}, config, connectionConfig)

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: mergedConfig,
      isPrimary: true,
    })
  }

  public async saveSite() {
    return this.boundEditorSDK.editor.save()
  }

  public async saveSiteIfUnsaved() {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    return isSiteSaved || this.saveSite()
  }

  @undoable()
  public async createCollection(componentRef: ComponentRef, extraBiData = {}): Promise<string> {
    const primaryConnection = await this.getComponentConnection(componentRef)
    if (!_.get(primaryConnection, 'config')) {
      return
    }
    const { preset, plugins, collectionId: existingCollectionId } = primaryConnection.config

    const existingValidCollectionId = getValidCollectionId(componentRef.id, existingCollectionId)
    if (
      existingValidCollectionId &&
      (await this.collectionsApi.isCollectionExists(existingValidCollectionId))
    ) {
      return existingValidCollectionId
    }

    const biData = {
      template: preset,
      form_comp_id: componentRef.id,
      ...extraBiData,
    }

    const fields = await this.fields.getFieldsSortByXY(componentRef)
    const fieldKeyCallback = (fieldComponent, fieldKey) =>
      this.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
    const collectionId = await this.collectionsApi.createCollection(
      { preset, fields, plugins, fieldKeyCallback },
      { startBi: biData, endBi: biData }
    )

    await this.setComponentConnection(componentRef, {
      collectionId: `${componentRef.id}_${collectionId}`,
    })

    await this.editDraft(componentRef, collectionId)

    await this.saveSite()

    return collectionId
  }

  public async getOwnerEmailId() {
    const data = await this.getOwnerEmail()
    return data ? (isWixEmployeeEmail(data.email) ? '' : data.emailId) : ''
  }

  public async getOwnerEmail() {
    return this.remoteApi.getOwnerEmail().catch(() => null)
  }

  public async editDraft(componentRef, collectionId = null) {
    const componentConnection = await this.getComponentConnection(componentRef)
    if (!_.get(componentConnection, 'config')) {
      return
    }
    const { formName } = componentConnection.config
    const connectedFields = await this.fields.getFieldsSortByXY(componentRef)

    const hasCaptchaField = connectedFields.some(
      field => field.fieldType === FormsFieldPreset.GENERAL_RECAPTCHA
    )
    const plugins = getPlugins(componentConnection)
    const pluginsV2Dto = await this._preparePluginsV2(plugins, hasCaptchaField, componentRef)

    const formData: any = {
      formId: componentRef.id,
      formName,
      fields: _.map(connectedFields, connectedField => ({
        fieldId: connectedField.componentRef.id,
        fieldName: connectedField.crmLabel,
      })),
      pluginsV2: pluginsV2Dto,
    }

    if (collectionId) {
      formData.collectionId = collectionId
    }

    return this.remoteApi.editDraft(formData)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  public removeComponent(componentRef: ComponentRef, _biData = {}) {
    return this.removeComponentRef(componentRef)
  }

  public removeComponentRef(componentRef: ComponentRef) {
    return componentRef
      ? this.boundEditorSDK.components.remove({ componentRef })
      : Promise.resolve()
  }

  public async getButtonLabel(componentRef: ComponentRef): Promise<string> {
    const { label } = await this.boundEditorSDK.components.data.get({ componentRef })
    return label
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.SUBMIT_SETTINGS].UPDATE_SUBMIT_VALUE })
  public updateButtonLabel(componentRef: ComponentRef, buttonLabel, _biData = {}) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label: buttonLabel },
    })
  }

  public async isTemplate(): Promise<boolean> {
    return !(await this.boundEditorSDK.info.isSiteSaved())
  }

  public getMetaSiteId(): Promise<string> {
    return this.boundEditorSDK.info.getMetaSiteId()
  }

  public getAppDefinitionId(): Promise<string> {
    return this.boundEditorSDK.document.info.getAppDefinitionId()
  }

  public getEditorSessionId(): Promise<string> {
    return this.boundEditorSDK.info.getEditorSessionId()
  }

  public async isMobileMode(): Promise<boolean> {
    return (await this.boundEditorSDK.info.getEditorMode()) === 'mobile'
  }

  private async _isComponentExists(componentRef: ComponentRef): Promise<boolean> {
    try {
      const componentLayout = await this.boundEditorSDK.components.layout.get({
        componentRef,
      })
      return !!componentLayout
    } catch (ex) {
      return false
    }
  }

  private async _isSubscribeFieldExistsWithoutEmailField(
    controllerRef: ComponentRef,
    emailFields: ComponentRef[]
  ) {
    const subscribeFields = await this.findConnectedComponentsByFieldType(
      controllerRef,
      FormsFieldPreset.GENERAL_SUBSCRIBE
    )

    const isSubscribeFieldExistsWithoutEmailField =
      subscribeFields.length > 0 && emailFields.length == 0

    return isSubscribeFieldExistsWithoutEmailField
  }

  private async _isEmailFieldMissing(controllerRef: ComponentRef, emailFields: ComponentRef[]) {
    const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formRef)
    const hasGetSubscribersPlugin = _.chain(config)
      .get('plugins')
      .find({ id: FormPlugin.GET_SUBSCRIBERS })
      .value()

    if (!hasGetSubscribersPlugin) {
      return false
    }

    return emailFields.length === 0
  }

  public async isEmailFieldMissing(controllerRef): Promise<MissingField> {
    const emailFields = await this.findConnectedComponentsByFieldType(
      controllerRef,
      FormsFieldPreset.EMAIL
    )

    const emailFieldMissing =
      (await this._isSubscribeFieldExistsWithoutEmailField(controllerRef, emailFields)) ||
      (await this._isEmailFieldMissing(controllerRef, emailFields))

    return emailFieldMissing ? { type: FieldNameType.PRESET, name: FormsFieldPreset.EMAIL } : null
  }

  public async isFieldMissingByRole(componentRef, role): Promise<MissingField> {
    const ref = await this.findComponentByRole(componentRef, role)
    return ref ? null : { type: FieldNameType.ROLE, name: role }
  }

  public async isMissingFieldByPreset(
    controllerRef: ComponentRef,
    fieldPreset: FieldPreset
  ): Promise<MissingField> {
    const fields = await this.findConnectedComponentsByFieldType(controllerRef, fieldPreset)

    return fields.length === 0 ? { type: FieldNameType.PRESET, name: fieldPreset } : null
  }

  public async isAppWidget(componentRef) {
    return (
      (await this.boundEditorSDK.components.getType({ componentRef })) ===
      'platform.components.AppWidget'
    )
  }

  /*
   TEMP SOLUTION: When we delete form we will get in listConnectedComponents (few lines below) all components
   including other forms in other pages when user duplicated the page - we want to skip the implementation below
   when we got here with duplicated form the user didn't deleted manually
   TODO: Wrap the code below in if-statement when we will have the AppWidget
   `
   const connectedViaAppWidget = await this.boundEditorSDK.components.getType({ componentRef }) === '...AppWidget'
   if (!connectedViaAppWidget) {
   // Do the code below for backwards compatibility
   }
   `
   */
  private async _handleFormDeletion({
    componentRef,
    componentConnection,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
  }) {
    const { controllerRef, config } = componentConnection
    if (_.get(config, 'isDummyForm')) {
      return
    }
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.DELETE_FORM,
      esi: esi,
      form_comp_id: componentRef.id,
      template: config.preset,
    })

    const duplicatedForm = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    if (duplicatedForm) {
      return
    }

    if (await this.isMobileMode()) {
      return
    }

    const connectedRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    await Promise.all(
      connectedRefs.map(async connectedComponentRef =>
        this.boundEditorSDK.components.remove({ componentRef: connectedComponentRef })
      )
    )

    const isComponentExists = await this._isComponentExists(controllerRef)
    if (isComponentExists) {
      await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
    }
  }

  // NOTE:
  // This is not working well with duplicated forms, it can return the wrong form component (depends who is first in the array)
  // Should be fixed with AppWidget
  private async _handleFieldDeletion({
    componentConnection,
  }: {
    componentConnection: ComponentConnection
  }) {
    const {
      role,
      controllerRef,
      config: { fieldType },
    } = componentConnection
    const formComponentRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)

    if (!formComponentRef) {
      return
    }

    const formComponentConnection = await this.getComponentConnection(formComponentRef)
    const {
      config: { plugins },
    } = formComponentConnection

    if (role === ROLE_SUBMIT_BUTTON) {
      const {
        config: { preset },
      } = formComponentConnection
      const esi = await this.getEditorSessionId()

      await this._deleteSubmissionButton(formComponentRef, plugins, {
        startBi: {
          form_comp_id: formComponentRef.id,
          template: preset,
          esi,
        },
      })
    } else if (role === ROLE_MESSAGE) {
      const {
        config: { successActionType },
      } = formComponentConnection

      if (
        this._shouldOpenTooltipForDeletedSuccessMessage(
          successActionType || SuccessActionTypes.SHOW_MESSAGE,
          formComponentRef
        )
      ) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.SUCCESS_MESSAGE_DELETED,
          plugins,
        })
      }
    } else if (role === ROLE_DOWNLOAD_MESSAGE) {
      // TODO merge ifs
      const {
        config: { successActionType },
      } = await this.getComponentConnection(formComponentRef)
      if (successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT && formComponentRef) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.DOWNLOAD_MESSAGE_DELETED,
          plugins,
        })
      }
    } else if (fieldType === FormsFieldPreset.EMAIL) {
      const shouldRestoreNotification = await this.isEmailFieldMissing(controllerRef)

      if (shouldRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.EMAIL_FIELD_DELETED,
          plugins,
        })
      } //TODO: Extract this configuration without knowledge about specific plugin
    } else if (REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES.includes(fieldType)) {
      await this.popNotificationAction({
        componentRef: formComponentRef,
        notificationTrigger: fieldType,
        plugins,
      })
    } else if (role === ROLE_PREVIOUS_BUTTON) {
      const shouldShowRestoreNotification = await this.steps.isPreviousButtonMissing(
        formComponentRef
      )

      if (shouldShowRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.PREVIOUS_BUTTON_DELETED,
          plugins,
        })
      }
    } else if (role === ROLE_NEXT_BUTTON) {
      const shouldShowRestoreNotification = await this.steps.isNextButtonMissing(formComponentRef)

      if (shouldShowRestoreNotification) {
        await this.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.NEXT_BUTTON_DELETED,
          plugins,
        })
      }
    }

    const selectedComponentRefs = await this.boundEditorSDK.selection.getSelectedComponents()
    if (!selectedComponentRefs.length) {
      await this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: componentConnection.controllerRef,
      })
    }
  }

  private _shouldOpenTooltipForDeletedSuccessMessage(
    successActionType: SuccessActionTypes,
    componentRef: ComponentRef
  ) {
    return successActionType === SuccessActionTypes.SHOW_MESSAGE && componentRef
  }

  public async handleDelete(componentRef: ComponentRef, componentConnection: ComponentConnection) {
    const { role } = componentConnection

    if (role === ROLE_FORM) {
      await this._handleFormDeletion({ componentRef, componentConnection })
    } else {
      await this._handleFieldDeletion({ componentConnection })
    }
  }

  public async addMandatoryFieldByPreset(formRef, fieldPreset) {
    const formComponentConnection = await this.getComponentConnection(formRef)

    // TODO: Move outside this function when doing plugin system
    if (fieldPreset === RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN) {
      return this.fields.restoreLoginLink(formRef)
    }

    const fieldProps: FieldProperties = ALL_FIELDS_DATA[fieldPreset]

    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)

    this.fields.addField(formRef, _.get(formComponentConnection, 'config'), {
      commonStyles,
      extraData: {
        ...fieldProps.extraData,
      },
      fieldType: fieldPreset,
    })
  }

  public async addMandatoryEmailField(formRef) {
    const formComponentConnection: ComponentConnection = await this.getComponentConnection(formRef)
    const connectedEmailFields = await this.findConnectedComponentsByFieldType(
      formComponentConnection.controllerRef,
      FormsFieldPreset.EMAIL
    )
    if (connectedEmailFields.length) {
      return
    }

    const email: FieldProperties = ALL_FIELDS_DATA[FormsFieldPreset.EMAIL].properties
    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)

    this.fields.addField(formRef, _.get(formComponentConnection, 'config'), {
      commonStyles,
      extraData: {
        ...email.extraData,
        props: { required: true },
        connectionConfig: { crmType: CRM_TYPES.EMAIL, crmTag: CRM_TAGS.MAIN },
      },
      fieldType: FormsFieldPreset.EMAIL,
    })
  }

  private async _deleteSubmissionButton(componentRef, plugins, biData) {
    const submitButtonExists = await this.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    if (submitButtonExists) {
      return
    }
    return this._onSubmissionButtonDelete(componentRef, plugins, biData)
  }

  @withBi({ startEvid: EVENTS.EDITOR.DELETE_SUBMISSION_BUTTON })
  private _onSubmissionButtonDelete(componentRef: ComponentRef, plugins: Plugin[], _biData) {
    return this.popNotificationAction({
      componentRef,
      notificationTrigger: NOTIFICATION_EVENTS.SUBMISSION_BUTTON_DELETED,
      plugins,
    })
  }

  private _reportPaymentNotificationBi(componentRef: ComponentRef) {
    this.biLogger.log({
      evid: EVENTS.EDITOR.PAYMENT_NOTIFICATION_SETUP_CLICK,
      form_comp_id: componentRef.id,
    })
  }

  public async popNotificationAction({
    componentRef,
    plugins,
    notificationTrigger,
  }: {
    componentRef: ComponentRef
    plugins: Plugin[]
    notificationTrigger: NOTIFICATION_EVENTS | FieldPreset
  }) {
    const isMobileMode = await this.isMobileMode()
    const plugin = getActivePlugin(convertPluginsToFormsPlugins(plugins))
    const name = _.camelCase(notificationTrigger)

    const message = isMobileMode
      ? getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.mobileText`,
        })
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.text`,
        })

    const linkText = isMobileMode
      ? ''
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.linkText`,
        })

    this.boundEditorSDK.editor
      .showNotification({
        title: translations.t(`restoreNotification.title`),
        message,
        type: 'info',
        link: {
          caption: linkText,
        },
      })
      .then(shouldRestore => {
        if (!shouldRestore) {
          return
        }

        switch (notificationTrigger) {
          case NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED:
            this._reportPaymentNotificationBi(componentRef)
            this.managePanels.openComponentPanel(
              componentRef,
              PanelName.NEW_FORM_SETTINGS,
              _.noop,
              {
                displayedTab: TABS.PAYMENT,
              }
            )
            break
          case NOTIFICATION_EVENTS.SUCCESS_MESSAGE_DELETED:
            this.fields.restoreHiddenMessage(componentRef)
            break
          case NOTIFICATION_EVENTS.DOWNLOAD_MESSAGE_DELETED:
            this.fields.restoreDownloadDocumentMessage(
              componentRef,
              translations.t('settings.successMessage.download')
            )
            break
          case NOTIFICATION_EVENTS.EMAIL_FIELD_DELETED:
            this.addMandatoryEmailField(componentRef)
            break
          case NOTIFICATION_EVENTS.SUBMISSION_BUTTON_DELETED:
            this.fields.restoreSubmitButton(componentRef)
            break
          case NOTIFICATION_EVENTS.PREVIOUS_BUTTON_DELETED:
            this.steps.restorePreviousButton(componentRef)
            break
          case NOTIFICATION_EVENTS.NEXT_BUTTON_DELETED:
            this.steps.restoreNextButton(componentRef)
            break
          default:
            this.addMandatoryFieldByPreset(componentRef, notificationTrigger)
            break
        }
      })
  }

  public async isCollectionExists(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ) {
    const {
      config: { collectionId },
    } = componentConnection || (await this.getComponentConnection(componentRef))
    const validCollectionId = getValidCollectionId(componentRef.id, collectionId)
    return this.collectionsApi.isCollectionExists(validCollectionId)
  }

  public getLocale() {
    return this.boundEditorSDK.environment.getLocale()
  }

  private async _preparePluginsV2(plugins: Plugin[], hasCaptchaField, componentRef?) {
    const pluginsV2: PluginV2[] = await Promise.all(
      plugins.map(async (plugin: Plugin) => {
        switch (plugin.id) {
          case FormPlugin.FORM_BUILDER:
          case FormPlugin.GET_SUBSCRIBERS:
          case FormPlugin.REGISTRATION_FORM:
            return { [_.camelCase(plugin.id)]: {} }
          case FormPlugin.PAYMENT_FORM:
            return {
              paymentForm: {
                currency: await this.getCurrency(),
                items: _.values(_.get(plugin, 'payload.items')),
              },
            }
          case FormPlugin.MULTI_STEP_FORM:
            const stepComponents = await this.boundEditorSDK.components.getChildren({
              componentRef,
            })
            return {
              multiStepForm: {
                steps: stepComponents.length,
              },
            }
          default:
            return null
        }
      })
    )

    if (hasCaptchaField) {
      const captchaPlugin: PluginV2 = { captcha: {} }
      pluginsV2.push(captchaPlugin)
    }

    return _.compact(pluginsV2)
  }

  public async sendAllFormsData() {
    const collectionsById = await this.collectionsApi.getCollectionMapById()
    const allFormRefs = await this.getAllFormsRefs()

    const forms = await Promise.all(
      allFormRefs.map(async formRef => {
        if (!formRef) {
          return
        }

        const componentConnection = await this.getComponentConnection(formRef)
        const { config } = componentConnection
        const validCollectionId = getValidCollectionId(formRef.id, config.collectionId)
        const fields = await this.fields.getFieldsSortByXY(formRef)
        let hasCaptchaField = false

        const formFields = _.map(fields, field => {
          const fieldDef: FormFieldDefinition = {
            fieldId: field.componentRef.id,
            fieldName: field.crmLabel,
            fieldCollectionType: field.collectionFieldType,
          }

          if (field.fieldType === FormsFieldPreset.GENERAL_RECAPTCHA) {
            hasCaptchaField = true
          }

          if (allowCollectionSync(field.fieldType)) {
            if (field.collectionFieldKey) {
              fieldDef.fieldCollectionKey = field.collectionFieldKey
            }

            const fieldProperties = ALL_FIELDS_DATA[field.fieldType].properties

            if (!fieldDef.fieldCollectionType && fieldProperties.collectionFieldType) {
              fieldDef.fieldCollectionType = fieldProperties.collectionFieldType
            }
          }

          return fieldDef
        })

        const plugins = getPlugins(componentConnection)
        const pluginsV2Dto = await this._preparePluginsV2(plugins, hasCaptchaField, formRef)

        return {
          formId: formRef.id,
          formName: config.formName,
          collectionId: collectionsById[validCollectionId] && validCollectionId,
          pluginsV2: pluginsV2Dto,
          fields: formFields,
        }
      })
    )
    const formData = {
      forms: _.filter(forms, x => !!x),
    }
    return this.remoteApi.publishSite(formData)
  }

  public isRegistrationForm(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.REGISTRATION_FORM })
  }

  public isMultiStepForm(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.MULTI_STEP_FORM })
  }

  public isGetSubscribers(componentRef: ComponentRef): Promise<boolean> {
    return this._isPluginExists(componentRef, { id: FormPlugin.GET_SUBSCRIBERS })
  }

  private async _isPluginExists(componentRef: ComponentRef, plugin: Plugin): Promise<boolean> {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins: Plugin[] = _.get(config, 'plugins', [])
    return !!_.find(plugins, plugin)
  }

  public hasConnectedPayment(): Promise<boolean> {
    return this.remoteApi
      .getConnectedPayments()
      .then(connectedPayments => connectedPayments.paymentMethods.length > 0)
      .catch(() => false)
  }

  public getCurrency() {
    return this.boundEditorSDK.info.getCurrency()
  }

  // TODO: Merge with other config fetch
  // TODO: One possible solution, replace with getPlugins(componentConnection)
  public async getFormConfigData(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins = _.map(_.get(config, 'plugins', []), 'id')
    const preset = _.get(config, 'preset')

    return { plugins, preset }
  }

  public async reportBiFirstSave() {
    const controllers: {
      controllerRef: ComponentRef
    }[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formRef: ComponentRef = await this.findConnectedComponent(
      controllers[0].controllerRef,
      ROLE_FORM
    )
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.USER_SAVE_TEMPLATE_WITH_FORM,
      form_comp_id: formRef.id,
      template: preset,
      templateId: this.siteId,
    })
  }

  public async reportBiAppWidgetPasted(formRef: ComponentRef): Promise<void> {
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.PASTE_APP_WIDGET,
      source_name: 'copy form',
      form_comp_id: formRef.id,
      template: preset,
    })
  }

  public selectComponent(componentRef: ComponentRef): Promise<null> {
    return this.boundEditorSDK.selection.selectComponentByCompRef({
      compsToSelect: [componentRef],
    })
  }

  public async isWixChatInstalled(): Promise<boolean> {
    try {
      const isInstalled = await this.boundEditorSDK.document.tpa.isApplicationInstalled({
        appDefinitionId: '14517e1a-3ff0-af98-408e-2bd6953c36a2',
      })
      return isInstalled
    } catch (ex) {
      return false
    }
  }

  public async createTag(tagName: string) {
    return this.remoteApi.createTag(tagName) // TODO move to add-form only
  }

  public async addHeightToContainers(componentRef: ComponentRef, extraHeight: number) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const allAncestors = [componentRef, ...ancestors]
    const orderedAncestors: ComponentRef[] =
      extraHeight > 0 ? _.reverse(allAncestors) : allAncestors

    const heights = _.keyBy(
      await this.boundEditorSDK.components.get({
        componentRefs: orderedAncestors,
        properties: ['layout'],
      }),
      'componentRef.id'
    )

    const addHeight = async (compRef: ComponentRef) => {
      if (!heights[compRef.id].layout.height) {
        return Promise.resolve()
      }

      return this.boundEditorSDK.components.layout.update({
        componentRef: compRef,
        layout: { height: heights[compRef.id].layout.height + extraHeight },
      })
    }

    return orderedAncestors.reduce(
      (previousPromise, nextAncestor) => previousPromise.then(() => addHeight(nextAncestor)),
      Promise.resolve()
    )
  }

  public async logFetchThemesFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'global design',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public async logFetchPresetsFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'preset',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public isExperimentEnabled(experiment) {
    return this.experiments.enabled(experiment)
  }

  public shouldReplaceManageFieldsWithAddFieldGfpp() {
    return this.experiments.enabled('specs.cx.FormBuilderReplaceManageFieldsWithAddFieldGfpp')
  }

  public isNewContactSync() {
    return this.experiments.enabled('specs.cx.FormBuilderContactsListTab')
  }

  public async convertFormToAppWidget(componentRef: ComponentRef) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({
      componentRef,
    })
    const containerRef = _.first(ancestors) || (await this.boundEditorSDK.pages.getCurrent())
    const { config: connectionConfig } = await this.getComponentConnection(componentRef)
    const { layout, style, components } = (
      await this.boundEditorSDK.components.get({
        componentRefs: [componentRef],
        properties: ['layout', 'style', 'components'],
      })
    )[0]

    await this.removeComponentRef(componentRef)

    const changeComponentConnections = components =>
      components.reduce((acc, component) => {
        if (component.components) {
          component.components = changeComponentConnections(component.components)
        }

        if (component.connections) {
          const primaryConnection = _.find(component.connections.items, comp => comp.isPrimary)
          primaryConnection.controllerId = 'data_item_id_placeholder'
        }
        acc = acc.concat([component])
        return acc
      }, [])

    const containerDefinition = {
      data: {
        layout,
        style,
        type: 'Container',
        componentType: COMPONENT_TYPES.FORM_CONTAINER,
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
        components: changeComponentConnections(components),
      },
      connectionConfig,
    }

    const controllerRef = await this.addForm.addAppWidget(containerRef, containerDefinition)
    const formRef = await this.getFormContainerOfAppWidget(controllerRef)
    await this.addForm.updateFormCollectionId(formRef, connectionConfig.collectionId)

    await this.appState.setState([controllerRef])
  }

  public async makeActionOnMobileAndDesktop(
    desktopRef: ComponentRef,
    action: (compRef: ComponentRef) => Promise<any>
  ): Promise<any[]> {
    const mobileRef: ComponentRef = { id: desktopRef.id, type: 'MOBILE' }
    if (await this._isComponentExists(mobileRef)) {
      return Promise.all([action(mobileRef), action(desktopRef)])
    }
    return action(desktopRef)
  }

  public panelGoBack() {
    return Promise.resolve()
  }

  public panelUpgrade() {
    return Promise.resolve()
  }

  public panelDelete() {
    return Promise.resolve()
  }

  public async getAllFormsRefs({ shouldExcludeSignupForm = false } = {}): Promise<ComponentRef[]> {
    const allControllers: ControllerRef[] = await this.boundEditorSDK.controllers.listAllControllers()
    const allFormRefs: ComponentRef[] = await Promise.all(
      allControllers.map(async ({ controllerRef }) => {
        const connectedRef = this.findConnectedComponent(controllerRef, ROLE_FORM)

        if (shouldExcludeSignupForm && (await this.isRegistrationForm(await connectedRef)))
          return null
        return connectedRef
      })
    )
    return allFormRefs.filter(formRef => !!formRef)
  }

  public async getAllFormsRefsAndConfigs(): Promise<ComponentRefAndConfig[]> {
    const allFormsRefs = await this.getAllFormsRefs()
    return Promise.all(
      allFormsRefs.map(async componentRef => {
        const { config } = await this.getComponentConnection(componentRef)
        return {
          componentRef,
          componentConfig: config,
        }
      })
    )
  }

  public async createMissingFormLabels(allForms: ComponentRefAndConfig[]) {
    const formsWithMissingLabel = allForms.filter(
      form =>
        form.componentConfig && form.componentConfig.formName && !form.componentConfig.formLabelId
    )
    if (formsWithMissingLabel.length) {
      await Promise.all(
        formsWithMissingLabel.map(async form => {
          try {
            const {
              componentRef,
              componentConfig: { formName, labels },
            } = form
            const { id: newFormLabelId } = await this.createTag(formName)
            await this.setComponentConnection(componentRef, {
              formLabelId: newFormLabelId,
              labels: [...labels, newFormLabelId],
            })
          } catch {}
        })
      )
      await this.saveSite()
    }
  }
}
