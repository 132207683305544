import * as _ from 'lodash'

export const undoable = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = async function(...args) {
    const res = await originalMethod.apply(this, args)
    await this.boundEditorSDK.history.add({ label: 'History' })
    return res
  }
}

const promiseQueue = []

export const dequeue = () => {
  promiseQueue.splice(0, 1)
}

export const withSync = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = function(...args) {
    let promise
    const applyMethod = () => originalMethod.apply(this, args)
    if (promiseQueue.length === 0) {
      promise = applyMethod()
    } else {
      promise = promiseQueue[promiseQueue.length - 1].then(applyMethod).catch(applyMethod)
    }
    promiseQueue.push(promise.then(dequeue).catch(dequeue))
    return promise
  }
}

export const withBi = ({ startEvid = {}, endEvid = {} } = {}) => (
  _target,
  _funcName,
  decorator
) => {
  const isBiData = arg => arg.startBi !== undefined || arg.endBi !== undefined
  const originalMethod = decorator.value

  decorator.value = async function(...args) {
    let res
    const biData = args[args.length - 1]

    if (isBiData(biData)) {
      if (!_.isEmpty(biData.startBi)) {
        this.biLogger.log({ evid: startEvid, ...biData.startBi })
      }
      res = await originalMethod.apply(this, args.slice(0, -1))
      if (!_.isEmpty(biData.endBi)) {
        this.biLogger.log({ evid: endEvid, ...biData.endBi })
      }
    } else {
      res = await originalMethod.apply(this, args)
    }

    return res
  }
}
